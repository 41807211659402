import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Spacer from "../components/spacer"
import { Link } from "gatsby"

const HeroBanner = () => {
  return (
  <div className='hero--banner p20 flex m-wrap m-ha'>
    <div className='w-100 h-100 bg-white max-1600 ma br-60 flex m-wrap shadow circ-gradient'>
      <div className='w-50 m-100 flex m-center'>
        <div className='p40 m-p30 max-550 ma fade--up' data-sal>
          <p className='m0 h0 m-mt40' dangerouslySetInnerHTML={{ __html: "Know your <i>people</i>" }}/>
          <div className='m-show p40'>
            <StaticImage placeholder='none' src={"../images/heroImages/heroImageMobile.png"} alt={"Know your <i>people</i>"}/>
          </div>
          <div className='mt40 white-space large max-450'>
            {"Let’s be frank, surveys suck. That’s why Pulsi is changing the way insights are gathered.\n\nThink of us like an ‘anti-survey, survey company’. Pulsi helps you engage, connect and understand your audience instantly, in real-time."}
          </div>
          <div className='flex mt40 m-justify m-mb40'>
            <Link className='link button' to='/contact'>Find out more</Link> 
          </div>
        </div>
      </div>
      <div className='w-50 m-hide flex'>  
        <div className='max-600 w-100 ma'>
          <Spacer />
          <Spacer />
          <div className='ratio-1-1 pos-rel delay-100 fade--up mr20' data-sal>
            <StaticImage placeholder='none' className='bg-image contain' src={"../images/heroImages/heroImage.png"} alt={"Know your <i>people</i>"}/>
          </div>
          <Spacer />
          <Spacer />
        </div>
      </div>
    </div>
  </div>
  )
}

export default HeroBanner
