import React from 'react';
import { Link } from "gatsby"
import { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide} from 'swiper/react'
import { StaticImage } from "gatsby-plugin-image"
import Spacer from "../components/spacer"
import 'swiper/css'; 

const WhoCard = () => {
  return (
  <div className='how-it--works p20 flex m-ha'>
    <div className='w-100 h-100 bg-black white m-wrap max-1600 ma br-60 flex shadow overflow-hidden'>
      <div className='w-50 flex m-100'>
        <div className='p40 max-500 ma fade--up' data-sal>
          <p className='small m0 mb20 m-mt20'>WHO’S IT FOR?</p>
          <p className='m0 h3 title-purple' dangerouslySetInnerHTML={{ __html: "For all business’,<br/><i>great</i> and <i>small</i>" }} /> 
          <div className='mt40 white-space large max-450'>
            {"Pulsi is perfect for anyone who gives a damn about what their people think; hotels, hospitality, corporate, government... you name it. If you want to get to know your audience—the best way to do it is with Pulsi."}
          </div>
          <div className='buttons mt40 flex'>
            <Link to='/contact' className='m0 button fill hover-outline'>Find out more</Link>
          </div>
        </div>
      </div>
      <div className='w-50 flex m-100'>  
        <div className='w-100 ma overflow-hidden fade--up delay-200' data-sal>
          <Spacer className='mt40 m-hide' />
          <Swiper className='app-carousel mt40' spaceBetween={40} slidesPerView={3} speed={750} loop={true} modules={[Navigation, Autoplay]} navigation={{nextEl: '.arrow.right', prevEl: '.arrow.left'}}>   
            <SwiperSlide>
              <div className='ratio-1-3 bg-light br-20 overflow-hidden pos-rel'>
                <StaticImage placeholder='none'  className='bg-image' src={"../images/whoIsItForImages/appScreenshotOne.png"} alt={"For all business’,<br/><i>great</i> and <i>small</i>"}/>
              </div>
            </SwiperSlide>
            
            <SwiperSlide>
              <div className='ratio-1-3 bg-light br-20 overflow-hidden pos-rel'>
                <StaticImage placeholder='none'  className='bg-image' src={"../images/whoIsItForImages/appScreenshotTwo.png"} alt={"For all business’,<br/><i>great</i> and <i>small</i>"}/>
              </div>
            </SwiperSlide>
            
            <SwiperSlide>
              <div className='ratio-1-3 bg-light br-20 overflow-hidden pos-rel'>
                <StaticImage placeholder='none'  className='bg-image' src={"../images/whoIsItForImages/appScreenshotThree.png"} alt={"For all business’,<br/><i>great</i> and <i>small</i>"}/>
              </div>
            </SwiperSlide>
         
          </Swiper>
          <div className='carousel-pagination mt40 m-mb40 pb40 ml20 m-center m-ml0'>
            <div className='arrow left mr10'/>
            <div className='arrow right'/>
          </div>
          <Spacer className='m-hide'/>
        </div>
      </div>
    </div>
  </div>
  )
}

export default WhoCard
