import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Banner = () => (
  <div className='h-100vh m-ha  bg-grey flex pos-rel overflow-hidden'>
    <div className='max-1600 ma w-100 pos-rel z-2 m-p20'>
      <div className='w-100 m-wa max-500 br-20 p40 bg-offwhite ma mr20 mr40 fade--up m-mt20vh m-mb20' data-sal>
        <p className='m0 small mb20 mt20'>WHAT MAKES US UNIQUE?</p>
        <p className='m0 h3' dangerouslySetInnerHTML={{ __html: "We’re an <i>insights company</i> empowering you and <i>your people</i>" }} />
        <div className='large mt40'>
          {"We provide real-time data for real-life situations. We believe genuine feedback can make business better. That’s why we’ve reinvented ‘the survey’ and replaced it with short and engaging pulses."}
        </div>
        <div className='buttons flex mt40 mb40 m-align-center'>
          <Link to='/signup' className='m0 hide button'>Sign Up</Link>
          <Link to='/contact' className='m0 button fill ml10 m-ma'>Find out more</Link>
        </div>
      </div>
    </div>
    <StaticImage placeholder='none'  className='bg-image' src={"../images/background.png"} alt={"We’re an <i>insights company</i> empowering you and <i>your people</i>"}/>
  </div>
)

export default Banner
