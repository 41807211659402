import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Cards = () => {
  return (
  <div className='cards p20'>
    <div className='max-1400 ma flex m-wrap'>
      <div className='w-50 m-100  flex flex-wrap'>
        <div className='h-50 m-ha w-100 p10'>
          <div className='bg-grey w-100 h-100 m-ratio-3-1 m-ha br-20 pos-rel overflow-hidden fade--up' data-sal>
            <StaticImage placeholder='none'  className='bg-image' src={"../images/informationImages/smallCardOne.png"} alt='Card'/>
          </div>
        </div>
        <div className='h-50 w-100 p10 flex'>
          <div className='w-100 h-100 m-ratio-1-1 m-ha pos-rel mr10 overflow-hidden fade--up' data-sal>
            <div className='bg-image br-20 overflow-hidden'>
              <StaticImage placeholder='none'  className='bg-image' src={"../images/informationImages/smallCardTwo.png"} alt='Card'/>
            </div>
          </div>
          <div className='w-100 h-100 m-ratio-1-1 m-ha pos-rel overflow-hidden ml10 fade--up delay-100' data-sal>
            <div className='bg-image br-20 overflow-hidden'>
              <StaticImage placeholder='none' className='bg-image' src={"../images/informationImages/smallCardThree.png"} alt='Card'/>
            </div> 
          </div>
        </div>
      </div>
      <div className='w-50 m-100 p10'>
        <div className='ratio-1-1 br-20 bg-grey pos-rel overflow-hidden fade--up delay-100' data-sal>
          <StaticImage placeholder='none'  className='bg-image' src={"../images/informationImages/smallCardFour.png"} alt='Card'/>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Cards
