import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Technology = () => (
  <div className='technology p20 overflow-hidden m-pb40'>
    <div className='max-1600 ma flex t-wrap'>
      <div className='w-50 t-100 flex'>
        <div className='ma max-500 fade--up' data-sal>
          <p className='m0 h3' dangerouslySetInnerHTML={{ __html: "Capturing <i>important information</i> for your business is <i>one tap away</i>" }}/>
          <div className='mt40 white-space large'>
            {"Pulsi beacons are location-based sensors that can be placed anywhere in your business. This makes it easy for your audience, or your staff, to easily tap and provide instance feedback when you need it most."}
          </div>
        </div>
      </div>
      <div className='w-50 t-100 m-mt40 flex'>
        <div className='max-550 m-max-300 t-max-350 w-100 ma'>
          <div className='technology-wrapper pos-rel'>
            <div className='image one m-mr20'>
              <div className='fade--up delay-100' data-sal>
                <div className='ratio-3-1 br-20 pos-rel overflow-hidden'>
                  <StaticImage placeholder='none'  className='bg-image' src={"../images/importantInfoImages/importantInfoTwo.png"} alt={"Capturing <i>important information</i> for your business is <i>one tap away</i>"}/>
                </div>
              </div>
            </div>
            <div className='ratio-1-1 bg-grey pos-rel overflow-hidden br-60'>
              <StaticImage placeholder='none'  className='bg-image' src={"../images/importantInfoImages/importantInfoOne.png"} alt={"Capturing <i>important information</i> for your business is <i>one tap away</i>"}/>
            </div>
            <div className='image two'>
              <div className='fade--up delay-200' data-sal>
                <div className='ratio-1-1 br-20 pos-rel overflow-hidden'>
                  <StaticImage placeholder='none'  className='bg-image' src={"../images/importantInfoImages/importantInfoThree.png"} alt={"Capturing <i>important information</i> for your business is <i>one tap away</i>"}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Technology
