import * as React from "react"
import { StaticImage} from "gatsby-plugin-image"


const Icons = () => {
  return (
  <div className='icons p20 bg-grey-100 w-gradient'>
    <div className='max-1600 ma grid'>
    
    	<div className={'single-icon p20 text-center white '+('brw1 m-bb1')}>
		    <div className={'mt20 mb20 fade--up delay-100'} data-sal>
		      <div className='icon pos-rel mla mb20'> 
		        <StaticImage placeholder='none' className='bg-image contain' src={"../images/icons/icon0.png"} alt={"Instantly deployable"}/>
		      </div>
		      <p className='m0 h5 max-200 ma'>{"Instantly deployable"}</p>
		    </div>
		</div>
		
		<div className={'single-icon p20 text-center white '+('brw1 m-bb1')}>
		    <div className={'mt20 mb20 fade--up delay-100'} data-sal>
		      <div className='icon pos-rel mla mb20'> 
		        <StaticImage placeholder='none'  className='bg-image contain' src={"../images/icons/icon1.png"} alt={"NFC technology for a hassle-free process"}/>
		      </div>
		      <p className='m0 h5 max-200 ma'>{"NFC technology for a hassle-free process"}</p>
		    </div>
		</div>
		
		<div className={'single-icon p20 text-center white '+('brw1 m-bb1')}>
		    <div className={'mt20 mb20 fade--up delay-100'} data-sal>
		      <div className='icon pos-rel mla mb20'> 
		        <StaticImage placeholder='none'  className='bg-image contain' src={"../images/icons/icon2.png"} alt={"Easy administration and access"}/>
		      </div>
		      <p className='m0 h5 max-200 ma'>{"Easy administration and access"}</p>
		    </div>
		</div>
		
		<div className={'single-icon p20 text-center white '+('')}>
		    <div className={'mt20 mb20 fade--up delay-100'} data-sal>
		      <div className='icon pos-rel mla mb20'> 
		        <StaticImage placeholder='none'  className='bg-image contain' src={"../images/icons/icon3.png"} alt={"Fun and interactive design"}/>
		      </div>
		      <p className='m0 h5 max-200 ma'>{"Fun and interactive design"}</p>
		    </div>
		</div>
    

    </div>
  </div>
  )
}


export default Icons

