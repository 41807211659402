import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Spacer from "../components/spacer"

const HeroBanner = () => (
  <div className='how-it--works p20 flex m-ha'>
    <div className='w-100 h-100 bg-white max-1600 ma br-60 flex m-wrap shadow overflow-hidden'>
      <div className='w-50 m-100 flex'>
        <div className='p40 max-500 ma fade--up' data-sal>
          <p className='small m0 mb20'>HOW IT WORKS?</p>
          <p className='m0 h3' dangerouslySetInnerHTML={{ __html: "Pulsi offers engaging ways to provide <i>feedback in-the-moment</i>, unearthing genuine, <i>real time</i> insights." }} /> 
          <div className='mt40 white-space large'>
            {"Pulsi makes providing feedback a joy which produces better, more genuine insights. We do this through a pulse; a very short questionnaire. And because the results are captured in-the-moment – businesses have every confidence they are *drum-roll* on the pulse."}
          </div>
        </div>
      </div>
      <div className='w-50 m-100 flex'>  
        <div className='max-650 w-100 mta mla mra mb0 fade--up delay-100' data-sal>
          <Spacer />
          <Spacer className='m-hide' />
          <StaticImage placeholder='none' className='transform-n-60' src={"../images/howItWorksImage.png"} alt={"Pulsi offers engaging ways to provide <i>feedback in-the-moment</i>, unearthing genuine, <i>real time</i> insights."}/>
        </div>
      </div>
    </div>
  </div>
)

export default HeroBanner
