import * as React from "react"
import Layout from "../components/layout"
import HeroBanner from "../components/heroBanner"
import HowItWorks from "../components/howItWorks" 
import WhosItFor from "../components/whosItFor" 
import Technology from "../components/technology" 
import Banner from "../components/banner"
import Spacer from "../components/spacer"
import Cards from "../components/cards"
import Icons from "../components/icons"
import Seo from "../components/seo"

const IndexPage = () => {

  return (
  <Layout page='home'>
      <Seo title={"Know your people"} />
      <HeroBanner/>
      <Spacer />
      <Icons/>
      <Spacer id='how' />
      <HowItWorks/>
      <Spacer />
      <Technology/>
      <Spacer id='who' />
      <WhosItFor />
      <Spacer />
      <Cards />
      <Spacer id='why' />
      <Banner/> 
  </Layout>
  )
}

export default IndexPage

